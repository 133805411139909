@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;500;600&display=swap);
.Header_Header__3VoE2 {
  position: fixed;
  height: 48px;
  top: 0;
  width: 100%;
  padding-left: 10px;
  background: black;
}

.Header_Logo__q1Zi3 {
  color: white;
  font-weight: bold;
}

.Warning_Warning__1xNri {
  position: fixed;
  top: 58px;
  left: 0;
  right: 0;
  background: #ff9595;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 77;
  height: 50px;
  padding: 0 10px;
}

.NPMLink_Icon__1vyyT {
  fill: #c70000;
}

.NPMLink_Icon__1vyyT:hover {
  opacity: 0.77;
}

.Package_Package__m_GBy {
  border-bottom: 1px solid #eee;
  padding: 20px;
}

.Package_Package__m_GBy:hover {
  background-color: #f7f7f7;
}

.Package_TitleRow__3qIhl {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Package_Name__1u8WN {
  color: black;
  font-weight: bold;
  margin-right: 10px;
}

.Package_Package__m_GBy label {
  font-weight: bold;
  margin-bottom: 12px;
  font-size: 12px;
  display: inline-block;
  min-width: 140px;
}

.Package_Package__m_GBy input,
.Package_Package__m_GBy select {
  padding: 5px;
  border: none;
  background: #eee;
  border-radius: 2px;
}

.Package_Plus__2l75r {
  fill: #0366d6;
  height: 24px;
  width: 24px;
}

.Search_Search__2kqFt {
  position: fixed;
  background: white;
  width: 100%;
  max-width: 800px;
  margin-top: 48px;
  padding-top: 20px;
  max-height: 100%;
}

.Search_SearchContainer__3fbqM {
  position: relative;
}

.Search_SearchInput__8y8ME {
  width: 100%;
  border: none;
  background: #eee;
  padding: 0 10px;
  line-height: 40px;
  border-radius: 2px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.Search_Search__2kqFt.Search_Closed__UyGIn .Search_SearchInput__8y8ME {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.Search_SearchCancel__16fTS {
  position: absolute;
  right: 10px;
  top: 6px;
  outline: none;
  border: none;
  padding: 0;
  fill: #a0a0a0;
  background: transparent;
}

.Search_SearchCancel__16fTS:disabled {
  fill: #dadada;
}

.Search_Packages__17G_8 {
  padding: 0;
  max-height: 100%;
  overflow: auto;
  border: 1px solid #eee;
  border-top: none;
  max-height: calc(100% - 28px); /* this might be in a variable */
}

.Search_Search__2kqFt.Search_Closed__UyGIn {
  height: auto;
}

.Environment_PackageList__28-WW {
  table-layout: fixed;
  border-collapse: collapse;
}

.Environment_PackageList__28-WW th {
  text-align: left;
  font-weight: 500;
}

.Environment_PackageList__28-WW td,
.Environment_PackageList__28-WW th {
  padding: 5px 10px;
}

.Environment_PackageList__28-WW tr > th:first-child,
.Environment_PackageList__28-WW tr > td:first-child {
  padding-left: 0;
}

.Environment_Remove__guyZr {
  border: 0;
  padding: 0;
  fill: #ec0404;
  outline: none;
  background: transparent;
}

.Environment_Link__1Nt4l {
  height: 24px;
  display: block;
}

.Environment_Spinner__2KkPV:before {
  content: " ";
  width: 0.7em;
  height: 0.7em;
  margin: auto 0.1em auto auto;
  padding: 0;
  background: none;
  border: 2px solid transparent;
  border-top-color: #0366d6;
  border-bottom-color: #0366d6;
  border-left-color: #0366d6;
  border-radius: 100%;
  -webkit-animation: Environment_circle__2OK-P 0.63s linear 0s infinite normal;
          animation: Environment_circle__2OK-P 0.63s linear 0s infinite normal;
  display: inline-block;
  font-size: 18px;
}

@-webkit-keyframes Environment_circle__2OK-P {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes Environment_circle__2OK-P {
  100% {
    transform: rotate(360deg);
  }
}

.Environments_Controls__aVT0l {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding: 10px 20px;
  background: white;
  border-top: 1px solid #eee;
  border-radius: 0 0 4px 4px;
}

.Environments_Controls__aVT0l button {
  margin-right: 15px;
}

.Environments_Icon__1XIHM {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  fill: #0366d6;
}

.Environments_Environment__1y0uU {
  background: #fefcfc;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  border-radius: 4px;
  border: 1px solid #eee;
}

.Environments_Wrapper__33Gvy {
  margin-top: 20px;
}

.Environments_TableWrapper__1JGFT {
  padding: 15px 20px;
}

.Environments_Name__2w_ct {
  font-weight: 500;
  border-left: 7px solid #e2e2e2;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  padding: 5px 10px;
}

.Environments_Title___NClQ {
  font-weight: 500;
  text-transform: uppercase;
}

.App_App__16ZpL {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
}

.App_Search__3IPKu {
  height: calc(100vh - 70px);
  width: calc(100% - 20px);
}

.App_Environments__UF1DD {
  margin-top: 100px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji;
  color: #333;
}

h4 {
  color: black;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  color: #0366d6;
  text-decoration: none;
}

button {
  align-self: center;
  background: none;
  display: flex;
  justify-content: space-evenly;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 2px;
  align-items: center;
  cursor: pointer;
  min-height: 28px;
  background: white;
}

button:hover {
  border-color: #0366d6;
}

