@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;500;600&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji;
  color: #333;
}

h4 {
  color: black;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  color: #0366d6;
  text-decoration: none;
}

button {
  align-self: center;
  background: none;
  display: flex;
  justify-content: space-evenly;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 2px;
  align-items: center;
  cursor: pointer;
  min-height: 28px;
  background: white;
}

button:hover {
  border-color: #0366d6;
}
