.PackageList {
  table-layout: fixed;
  border-collapse: collapse;
}

.PackageList th {
  text-align: left;
  font-weight: 500;
}

.PackageList td,
.PackageList th {
  padding: 5px 10px;
}

.PackageList tr > th:first-child,
.PackageList tr > td:first-child {
  padding-left: 0;
}

.Remove {
  border: 0;
  padding: 0;
  fill: #ec0404;
  outline: none;
  background: transparent;
}

.Link {
  height: 24px;
  display: block;
}

.Spinner:before {
  content: " ";
  width: 0.7em;
  height: 0.7em;
  margin: auto 0.1em auto auto;
  padding: 0;
  background: none;
  border: 2px solid transparent;
  border-top-color: #0366d6;
  border-bottom-color: #0366d6;
  border-left-color: #0366d6;
  border-radius: 100%;
  animation: circle 0.63s linear 0s infinite normal;
  display: inline-block;
  font-size: 18px;
}

@keyframes circle {
  100% {
    transform: rotate(360deg);
  }
}
