.Package {
  border-bottom: 1px solid #eee;
  padding: 20px;
}

.Package:hover {
  background-color: #f7f7f7;
}

.TitleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Name {
  color: black;
  font-weight: bold;
  margin-right: 10px;
}

.Package label {
  font-weight: bold;
  margin-bottom: 12px;
  font-size: 12px;
  display: inline-block;
  min-width: 140px;
}

.Package input,
.Package select {
  padding: 5px;
  border: none;
  background: #eee;
  border-radius: 2px;
}

.Plus {
  fill: #0366d6;
  height: 24px;
  width: 24px;
}
