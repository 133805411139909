.Warning {
  position: fixed;
  top: 58px;
  left: 0;
  right: 0;
  background: #ff9595;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 77;
  height: 50px;
  padding: 0 10px;
}
