.Header {
  position: fixed;
  height: 48px;
  top: 0;
  width: 100%;
  padding-left: 10px;
  background: black;
}

.Logo {
  color: white;
  font-weight: bold;
}
