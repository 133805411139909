.Search {
  position: fixed;
  background: white;
  width: 100%;
  max-width: 800px;
  margin-top: 48px;
  padding-top: 20px;
  max-height: 100%;
}

.SearchContainer {
  position: relative;
}

.SearchInput {
  width: 100%;
  border: none;
  background: #eee;
  padding: 0 10px;
  line-height: 40px;
  border-radius: 2px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.Search.Closed .SearchInput {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.SearchCancel {
  position: absolute;
  right: 10px;
  top: 6px;
  outline: none;
  border: none;
  padding: 0;
  fill: #a0a0a0;
  background: transparent;
}

.SearchCancel:disabled {
  fill: #dadada;
}

.Packages {
  padding: 0;
  max-height: 100%;
  overflow: auto;
  border: 1px solid #eee;
  border-top: none;
  max-height: calc(100% - 28px); /* this might be in a variable */
}

.Search.Closed {
  height: auto;
}
