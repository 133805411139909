.App {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
}

.Search {
  height: calc(100vh - 70px);
  width: calc(100% - 20px);
}

.Environments {
  margin-top: 100px;
}
