.Controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding: 10px 20px;
  background: white;
  border-top: 1px solid #eee;
  border-radius: 0 0 4px 4px;
}

.Controls button {
  margin-right: 15px;
}

.Icon {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  fill: #0366d6;
}

.Environment {
  background: #fefcfc;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 4px;
  border: 1px solid #eee;
}

.Wrapper {
  margin-top: 20px;
}

.TableWrapper {
  padding: 15px 20px;
}

.Name {
  font-weight: 500;
  border-left: 7px solid #e2e2e2;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 10px;
}

.Title {
  font-weight: 500;
  text-transform: uppercase;
}
